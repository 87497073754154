<template>
  <div id="app">
    <NavbarComp />
    <div class="content-wrapper">
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import NavbarComp from './components/NavbarComp.vue';


export default {
  name: 'App',
  components: {
    NavbarComp
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

body, * {
  font-family: 'Poppins', sans-serif;
}

/* Ensure the background covers the full page */
html, body, #app-wrapper, #app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
  background-color: #1a1a1a; /* Very dark gray */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  justify-content: flex-start; /* Align content at the top */
  align-items: center; /* Center horizontally */
}

/* Ensure the app content takes full width and height */
#app {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align content at the top */
  align-items: center;
  background-color: #1a1a1a;
  min-height: 100vh;
}

.navbar {
  width: 100%; /* Full browser width */
  background-color: #333;
  color: white;
  display: flex;
  justify-content: space-between; /* Space between items */
  align-items: flex-start; /* Top align items */
  padding: 10px 20px; /* Padding inside navbar */
  box-sizing: border-box;
}

/* Footer Navbar Styling */
.footer-navbar {
  width: 100%; /* Full browser width */
  background-color: #333; /* Match header background */
  color: white;
  text-align: center;
  padding: 10px 20px; /* Padding inside footer */
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000; /* Ensure it stays above other content */
}

/* Wrapper to center content horizontally */
.content-wrapper {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center; /* Center content horizontally */
  align-items: flex-start; /* Align content to the top */
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 50px; /* Adjust based on header height */
  margin-bottom: 50px; /* Adjust based on footer height */
}

/* Ensure the actual content inside the wrapper is centered horizontally */
.content {
  text-align: center; /* Center-align text/content inside */
  max-width: 800px; /* Optional: set a max-width for better readability */
  width: 100%; /* Ensure the content is responsive */
}
</style>
