<template>
    <div>
      <h1>Coming Soon!</h1>
      <p> Shopping Cart is Under Construction</p>
    </div>
  </template>
  
  <script lang="ts">
  import { defineComponent } from 'vue';
  
  export default defineComponent({
    name: 'CartView',
  });
  </script>
  
  <style scoped>
  * {
    color: #fff;
  }
  </style>
  