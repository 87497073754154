<template>
    <nav class="navbar">
      <div class="navbar-brand">
        <img src="@/assets/Watermark Initials_White.png" alt="MEJ" class="initials-size navbar-logo" />
        <router-link class="navbar-link" to="/">Mark Eoghan Jones Art</router-link>
      </div>
      <ul class="navbar-links">
        <li v-for="link in links" :key="link.text" :class="{ active: isActive(link.path) }">
          <template v-if="!link.external">
            <router-link class="nav-link" :to="link.path">{{ link.text }}</router-link>
          </template>
          <template v-else>
            <a
              class="nav-link"
              :href="link.path"
              @click.prevent="openInNewWindow(link.path)"
            >
              {{ link.text }}
            </a>
          </template>
        </li>
      </ul>
    </nav>
  </template>
  
  <script>
  export default {
    name: 'NavbarComp',
    computed: {
      currentRoute() {
        return this.$route.path;
      }
    },
    data() {
      return {
        links: [
          { text: 'Home', path: '/' },
          { text: 'Cart', path: '/cart' }
          ]
      };
    },
    methods: {
      isActive(path) {
        return this.currentRoute === path;
      },
      openInNewWindow(url) {
        const width = window.innerWidth;
        const height = window.innerHeight;
        window.open(url, '_blank', `noopener,noreferrer,width=${width},height=${height}`);
      }
    }
  };
  </script>
  
  <style scoped>
  /* Navbar container styling */
  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    background-color: #333;
    color: #fff;
  }
  
  /* Navbar brand styling */
  .navbar-brand {
    display: flex;
    align-items: center;
    justify-content: left;
    font-size: 1.5rem;
    flex: 1;
    gap: 10px;
  }
  
  /* Logo image styling */
  .initials-size {
    max-width: 70px;
    max-height: 80px;
    width: auto;
    height: auto;
  }
  
  /* Ensure the "Mark Eoghan Jones Art" link is plain and white */
  .navbar-link {
    color: #ffffff !important;
    text-decoration: none !important;
  }
  
  /* Navbar links container */
  .navbar-links {
    list-style: none;
    display: flex;
    gap: 1rem;
  }
  
  /* Individual list item styling */
  .navbar-links li {
    padding: 0.5rem;
    background-color: transparent;
    border: none;
  }
  
  /* Active link styling (underline) */
  .navbar-links li.active .nav-link {
    border-bottom: 2px solid #ffffff; /* White underline for active link */
  }
  
  /* Link styling for nav links */
  .navbar-links .nav-link {
    color: #ffffff !important;
    text-decoration: none !important;
    position: relative;
    display: inline-block;
    border-top: 2px solid transparent; /* Initially transparent border */
  }
  
  /* Hover effect using border-top */
  .navbar-links .nav-link:hover {
    border-top: 2px solid #ffffff !important; /* White border on hover */
    color: #ffffff !important; /* Ensure link text stays white on hover */
  }
  </style>
  